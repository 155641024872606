import React from "react";
var __jsx = React.createElement;
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import Footer from "../Footer";
import Header from "../Header";
import { Banner, Benefits, Docs, Features, News, Services } from "./components";
import { StyledHome, StyledHomeHero, StyledHomeHeroImageRow, StyledHomeHeroImageRowLast } from "./styled"; // import IconFirecrackers from '~/assets/images/firecrackers.svg'
// import IconCloud from '~/assets/images/cloud.svg'
// import IconLantern from '~/assets/images/lantern.svg'

import HeroLogo from "../../assets/images/hero-logo.svg";
import HeroPuzzle from "../../assets/images/hero-puzzle.svg";
import HeroCode from "../../assets/images/hero-code.svg";
import HeroCube from "../../assets/images/hero-cube.svg";

var Home = () => {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  return __jsx(React.Fragment, null, __jsx(Head, null, __jsx("title", null, t('meta_common_title')), __jsx("meta", {
    name: "description",
    content: t('meta_common_description')
  }), __jsx("meta", {
    name: "keywords",
    content: t('meta_common_keywords')
  })), __jsx(StyledHome, null, __jsx(Header, null), __jsx(StyledHomeHero, null, __jsx(StyledHomeHeroImageRow, null, __jsx("img", {
    src: HeroPuzzle,
    className: "puzzle",
    alt: "Icon"
  }), __jsx("img", {
    src: HeroLogo,
    className: "logo",
    alt: "Icon"
  })), __jsx(StyledHomeHeroImageRowLast, null, __jsx("img", {
    src: HeroCube,
    className: "cube",
    alt: "Icon"
  }), __jsx("img", {
    src: HeroCode,
    className: "code",
    alt: "Icon"
  })), __jsx(Banner, null)), __jsx(Features, null), __jsx(News, null), __jsx(Benefits, null), __jsx(Services, null), __jsx(Docs, null), __jsx(Footer, null)));
};

export default Home;