import React from "react";
var __jsx = React.createElement;
export var insertLink = (rawText, reg, link) => {
  var matches = rawText.match(reg);

  if (matches && matches.length) {
    var others = rawText.split(matches[0]);
    return __jsx("span", null, others[0], __jsx("a", {
      href: link
    }, matches[0]), others[1]);
  }

  return rawText;
};