import React from "react";
var __jsx = React.createElement;
import { useTranslation } from 'react-i18next';
import { StyledBanner, StyledHomeContent, StyledBannerTitle, StyledHomeSubTitle, StyledHomeInstantButton, StyledHomeDataGrid, StyledHomeDataPanel } from "../styled";
import { useGA, useTxDataSummary } from "../../../hooks";
import { formatPercentage, formatCountUnit } from "../../../utils/numbers"; // import { LOCALES } from '~/locales'
// import BtnDesktopBgZH from '~/assets/images/launch-dapp-desktop-bg-zh.svg'
// import BtnDesktopBgEN from '~/assets/images/launch-dapp-desktop-bg-en.svg'
// import BtnMobileBgZH from '~/assets/images/launch-dapp-mobile-bg-zh.svg'
// import BtnMobileBgEN from '~/assets/images/launch-dapp-mobile-bg-en.svg'

import ClickHand from "../../../assets/images/click-hand.svg";
export var Banner = () => {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t,
      i18n = _useTranslation.i18n; // const { isMobile } = useBreakpoint()


  var _useGA = useGA(),
      trackElClick = _useGA.trackElClick;

  var _useTxDataSummary = useTxDataSummary(),
      successRate = _useTxDataSummary.successRate,
      totalTxs = _useTxDataSummary.totalTxs,
      totalVolume = _useTxDataSummary.totalVolume,
      totalWallets = _useTxDataSummary.totalWallets; // const isZh = i18n.resolvedLanguage === LOCALES.ZH
  // const btnBg = isMobile
  //   ? isZh
  //     ? BtnMobileBgZH
  //     : BtnMobileBgEN
  //   : isZh
  //   ? BtnDesktopBgZH
  //   : BtnDesktopBgEN


  return __jsx(StyledBanner, null, __jsx(StyledHomeContent, null, __jsx(StyledBannerTitle, null, __jsx("p", null, t('slogan_1')), __jsx("p", {
    className: "gradient-text"
  }, t('slogan_2'))), __jsx(StyledHomeSubTitle, null, t('slogan_desc')), __jsx(StyledHomeInstantButton, {
    href: "/instant",
    "data-trackid": "webtl_startinstant",
    "data-trackdata": JSON.stringify({
      from: 'hp_top'
    }),
    onClick: trackElClick
  }, __jsx("img", {
    src: ClickHand,
    alt: "click"
  }), __jsx("span", null, t('launch_dapp'))), __jsx(StyledHomeDataGrid, null, __jsx(StyledHomeDataPanel, null, __jsx("h3", {
    className: "value"
  }, "$".concat(formatCountUnit(totalVolume), "+")), __jsx("p", {
    className: "label"
  }, t('total_volume'))), __jsx(StyledHomeDataPanel, null, __jsx("h3", {
    className: "value"
  }, "".concat(formatCountUnit(totalTxs), "+")), __jsx("p", {
    className: "label"
  }, t('total_transactions'))), __jsx(StyledHomeDataPanel, null, __jsx("h3", {
    className: "value"
  }, formatCountUnit(totalWallets)), __jsx("p", {
    className: "label"
  }, t('total_wallets'))), __jsx(StyledHomeDataPanel, null, __jsx("h3", {
    className: "value"
  }, formatPercentage(successRate, 2)), __jsx("p", {
    className: "label"
  }, t('total_order_success_rate'))))));
};