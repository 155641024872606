import React from "react";
var __jsx = React.createElement;
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import IconAnnouncement from "../../../assets/images/announce.svg";
import { useAnnouncement } from "../../../hooks";
import { StyledAnnouncement, StyledHomeContent, StyledAnnouncementItem } from "../styled";
export var Announcement = () => {
  var announcements = useAnnouncement();
  if (!(announcements !== null && announcements !== void 0 && announcements.length)) return __jsx(StyledAnnouncement, null, __jsx(StyledHomeContent, null, __jsx("p", {
    className: "carousel"
  })));
  return __jsx(StyledAnnouncement, null, __jsx(StyledHomeContent, null, __jsx(Carousel, {
    showThumbs: false,
    infiniteLoop: true,
    axis: "vertical",
    showIndicators: false,
    showStatus: false,
    showArrows: false,
    autoPlay: true
  }, announcements.map(t => {
    return __jsx(StyledAnnouncementItem, {
      key: t.url
    }, __jsx("a", {
      href: t.url
    }, __jsx("img", {
      src: IconAnnouncement,
      alt: "announcement"
    }), __jsx("span", null, t.title)));
  }))));
};