import styled from 'styled-components';
export var StyledHome = styled.div.withConfig({
  displayName: "StyledHome",
  componentId: "sc-10fuyou-0"
})(["position:relative;"]);
export var StyledHomeContent = styled.div.withConfig({
  displayName: "StyledHomeContent",
  componentId: "sc-10fuyou-1"
})(["display:flex;flex-flow:column nowrap;align-items:center;justify-content:center;padding:0 24px;max-width:1144px;width:100%;"]);
export var StyledHomeSection = styled.div.withConfig({
  displayName: "StyledHomeSection",
  componentId: "sc-10fuyou-2"
})(["display:flex;justify-content:center;align-items:center;"]);
export var StyledHomeTitle = styled.h2.withConfig({
  displayName: "StyledHomeTitle",
  componentId: "sc-10fuyou-3"
})(["font-size:48px;line-height:67px;text-align:center;color:#000000;font-weight:600;margin-bottom:86px;@media screen and (max-width:960px){font-size:36px;line-height:43px;margin-bottom:56px;}"]);
export var StyledHomeSubTitle = styled.p.withConfig({
  displayName: "StyledHomeSubTitle",
  componentId: "sc-10fuyou-4"
})(["font-size:22px;line-height:34px;text-align:center;color:#2a1c4f;margin:0;@media screen and (max-width:960px){font-size:16px;line-height:22px;}"]);
export var StyledHomeInstantButton = styled.a.withConfig({
  displayName: "StyledHomeInstantButton",
  componentId: "sc-10fuyou-5"
})(["display:flex;justify-content:center;align-items:center;margin:42px 0 100px;padding:13px 25px 15px;height:56px;text-decoration:none;outline:none;background:#5f4dbc;border-radius:69px;z-index:10;img{width:32px;height:32px;margin-right:12px;}span{font-size:20.3px;font-weight:500;line-height:28px;text-align:center;letter-spacing:1px;color:#ffffff;}&:hover{background:#775df8;}@media screen and (max-width:960px){margin:32px 0 60px;width:100%;}"]);
export var StyledHomeHero = styled.div.withConfig({
  displayName: "StyledHomeHero",
  componentId: "sc-10fuyou-6"
})(["overflow:hidden;position:relative;background:", ";"], _ref => {
  var theme = _ref.theme;
  return theme.colors.homeBgColor;
});
export var StyledHomeHeroImageRow = styled.div.withConfig({
  displayName: "StyledHomeHeroImageRow",
  componentId: "sc-10fuyou-7"
})(["display:flex;justify-content:space-between;position:absolute;top:149px;left:0;right:0;margin:0 auto;width:100%;max-width:1488px;img{width:200px;height:200px;}.puzzle{position:relative;top:105px;}@media screen and (max-width:1280px){display:none;}"]);
export var StyledHomeHeroImageRowLast = styled.div.withConfig({
  displayName: "StyledHomeHeroImageRowLast",
  componentId: "sc-10fuyou-8"
})(["display:flex;justify-content:space-between;position:absolute;top:478px;left:0;right:0;margin:0 auto;width:100%;max-width:1146px;img{width:120px;height:120px;}.cube{position:relative;top:254px;left:-67px;}@media screen and (max-width:1280px){display:none;}"]);
export var StyledHomeBGIconsContainer = styled.div.withConfig({
  displayName: "StyledHomeBGIconsContainer",
  componentId: "sc-10fuyou-9"
})(["display:flex;justify-content:space-between;position:absolute;top:0;left:0;right:0;margin:0 auto;width:100%;max-width:1440px;.firecrackers{position:absolute;top:56px;left:62px;width:309px;height:451px;z-index:99;}.lantern1{position:absolute;top:0;right:201.34px;width:84px;height:252px;z-index:99;}.lantern2{position:absolute;top:0;right:70px;width:120px;height:360px;z-index:99;}.cloud{width:196.58px;height:83.02px;}.left-cloud{position:relative;top:566px;left:14px;}.right-cloud{position:relative;top:390px;right:39.84px;}@media screen and (max-width:1280px){.firecrackers,.lantern1,.lantern2{display:none;}.cloud{width:115px;height:48.5px;}.left-cloud{top:22.22px;left:-30.27px;}.right-cloud{position:relative;top:157px;right:-73.17px;}}"]);
export var StyledBanner = styled(StyledHomeSection).withConfig({
  displayName: "StyledBanner",
  componentId: "sc-10fuyou-10"
})(["padding:221px 0 176px;z-index:10;@media screen and (max-width:960px){padding:2.5px 0 28px;}"]);
export var StyledBannerTitle = styled.h1.withConfig({
  displayName: "StyledBannerTitle",
  componentId: "sc-10fuyou-11"
})(["font-weight:600;font-size:70px;line-height:80px;text-align:center;color:#2a1c4f;margin-bottom:42px;z-index:10;p{margin:0;}.gradient-text{background-image:linear-gradient(180deg,#b82efa 0%,#775df8 100%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:transparent;-ms-text-fill-color:transparent;}@media screen and (max-width:960px){font-weight:600;font-size:44px;line-height:52px;text-align:center;color:#2a1c4f;margin-bottom:32px;}"]);
export var StyledAnnouncement = styled(StyledHomeSection).withConfig({
  displayName: "StyledAnnouncement",
  componentId: "sc-10fuyou-12"
})(["background:#f6f8fb;padding:24px 0 18px 0;.carousel .slide{height:30px !important;background:transparent !important;}@media screen and (max-width:960px){padding:13px 0 11px 0;}"]);
export var StyledAnnouncementItem = styled.div.withConfig({
  displayName: "StyledAnnouncementItem",
  componentId: "sc-10fuyou-13"
})(["background:transparent;height:30px;a{font-size:15px;line-height:26px;color:#363c4c;font-weight:300;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:inline-flex;align-items:center;justify-content:center;height:100%;img{width:unset;margin-right:12px;position:relative;top:1px;}}@media screen and (max-width:960px){a{font-size:13px;max-width:300px;}}"]);
export var StyledFeatures = styled(StyledHomeSection).withConfig({
  displayName: "StyledFeatures",
  componentId: "sc-10fuyou-14"
})(["position:relative;padding:126px 0;.carousel-root{width:100%;max-width:395px;.carousel{margin:0 auto;.slide{padding:0 8.5px;opacity:0.7;background:transparent !important;&.selected{opacity:1;}&:first-of-type.selected{margin-left:10%;}}.control-dots{display:flex;flex-flow:row nowrap;justify-content:space-between;position:static;text-align:center;margin:20.5px auto;padding:8px;width:212px;height:44px;background:#f7f7fa;border-radius:107.7px;}}}@media screen and (max-width:960px){padding-top:92px;padding-bottom:90px;", "{padding:0;}", "{padding:0 24px;}}"], StyledHomeContent, StyledHomeTitle);
export var StyledFeaturesContent = styled.div.withConfig({
  displayName: "StyledFeaturesContent",
  componentId: "sc-10fuyou-15"
})(["display:grid;grid-template-columns:1fr 1fr 1fr;grid-template-rows:1fr;grid-gap:60px 16px;"]);
export var StyledFeaturesItem = styled.div.withConfig({
  displayName: "StyledFeaturesItem",
  componentId: "sc-10fuyou-16"
})(["display:flex;flex-flow:column nowrap;justify-content:space-between;overflow:hidden;padding:36px 0 0 36px;max-width:472px;height:524px;border:1px solid;border-color:", ";border-radius:25px;background:", ";.item-content{text-align:left;padding-right:36px;margin-bottom:24px;.item-title{font-weight:600;font-size:28px;line-height:39px;color:#000000;margin-bottom:14px;}.item-desc{font-size:17px;line-height:26px;color:#000000;margin-bottom:14px;}.item-header{display:flex;align-items:center;margin-bottom:4px;.item-logo{width:24px;height:24px;margin-right:6px;}}}.figure{width:366px;align-self:flex-end;}@media screen and (max-width:992px){padding:24px 0 0 24px;.item-content{padding-right:24px;}}"], _ref2 => {
  var borderColor = _ref2.borderColor;
  return borderColor;
}, _ref3 => {
  var backgroundColor = _ref3.backgroundColor;
  return backgroundColor;
});
export var StyledFeaturesItemButton = styled.a.withConfig({
  displayName: "StyledFeaturesItemButton",
  componentId: "sc-10fuyou-17"
})(["display:inline-flex;align-items:center;text-decoration:none;outline:none;padding:5px 21px;height:36px;background:", ";border-radius:45px;span{font-size:17px;line-height:26px;color:#fff;}&:hover{background:", ";}"], _ref4 => {
  var backgroundColor = _ref4.backgroundColor;
  return backgroundColor;
}, _ref5 => {
  var hoverColor = _ref5.hoverColor;
  return hoverColor;
});
export var StyledBenefits = styled(StyledHomeSection).withConfig({
  displayName: "StyledBenefits",
  componentId: "sc-10fuyou-18"
})(["padding-top:126px;@media screen and (max-width:960px){padding-top:92px;}"]);
export var StyledBenefitsContent = styled.div.withConfig({
  displayName: "StyledBenefitsContent",
  componentId: "sc-10fuyou-19"
})(["display:grid;grid-template-columns:1fr 1fr;grid-template-rows:repeat(3,1fr);grid-gap:42px 104px;@media screen and (max-width:960px){grid-template-columns:1fr;grid-template-rows:repeat(5,1fr);grid-gap:32px;}"]);
export var StyledBenefitsItem = styled.div.withConfig({
  displayName: "StyledBenefitsItem",
  componentId: "sc-10fuyou-20"
})(["display:flex;align-items:flex-start;img{width:100px;height:100px;margin-right:32px;}.item-title{font-size:22px;line-height:35px;color:#000000;margin-bottom:0;}.item-desc{font-size:18px;line-height:29px;color:#777a8c;margin:4px 0 0;a{text-decoration-line:underline;color:#777a8c;&:hover{color:#5c4eb6;}}}@media screen and (max-width:960px){img{width:60px;height:60px;margin-right:15px;}}"]);
export var StyledServices = styled(StyledHomeSection).withConfig({
  displayName: "StyledServices",
  componentId: "sc-10fuyou-21"
})(["padding-top:177px;@media screen and (max-width:960px){padding-top:92px;}"]);
export var StyledServicesContent = styled.div.withConfig({
  displayName: "StyledServicesContent",
  componentId: "sc-10fuyou-22"
})(["display:grid;grid-template-columns:575px 476px;grid-template-rows:repeat(2,253px);grid-gap:23px 36px;@media screen and (max-width:960px){display:flex;flex-flow:column nowrap;align-items:center;}"]);
export var StyledServicesItem = styled.div.withConfig({
  displayName: "StyledServicesItem",
  componentId: "sc-10fuyou-23"
})(["position:relative;padding:24px;background:#f9f9f9;border:1px solid #dfd5ff;border-radius:24px;h3{font-weight:600;font-size:24px;line-height:38px;color:#000000;margin-bottom:10px;}p{font-size:18px;line-height:29px;color:#777a8c;margin-bottom:10px;}a{font-size:17px;line-height:26px;text-decoration-line:underline;color:#000000;&:hover{color:#5c4eb6;}}.tokenlomics{position:absolute;right:-30px;bottom:-28px;width:128px;height:114px;}.wallet{position:absolute;right:-30px;bottom:-18px;width:128px;height:114px;}&:first-child{grid-row:1 / 3;grid-column:1 / 2;padding:0;.main{padding:36px;}.placeholder{position:absolute;left:0;bottom:0;border-bottom-left-radius:24px;border-bottom-right-radius:24px;width:100%;height:166px;background:#e7f0ff;}.layer2{position:absolute;left:0;bottom:-32px;width:100%;}}@media screen and (max-width:960px){padding:36px;width:342px;height:329px;.tokenlomics{right:-29px;}.wallet{right:-25px;bottom:-12px;}&:first-child{height:500px;.layer2{bottom:-19px;}}&:last-child{height:369px;}}"]);
export var StyledHomeData = styled(StyledHomeSection).withConfig({
  displayName: "StyledHomeData",
  componentId: "sc-10fuyou-24"
})(["position:absolute;width:100%;", "{background:white;flex-flow:row nowrap;justify-content:space-between;padding:73px 40px 67px 56px;border-radius:16px;position:relative;top:-90px;}", "{text-align:center;margin-top:15px;}@media screen and (max-width:960px){padding-top:0;padding-bottom:20px;", "{flex-flow:column nowrap;padding:30px;width:calc(100% - 40px);box-sizing:border-box;top:-225px;margin-bottom:72px;}", "{margin-bottom:30px;}}"], StyledHomeContent, StyledHomeSubTitle, StyledHomeContent, StyledHomeSubTitle);
export var StyledHomeDataGrid = styled.div.withConfig({
  displayName: "StyledHomeDataGrid",
  componentId: "sc-10fuyou-25"
})(["display:grid;grid-template-columns:repeat(4,1fr);padding:33px 42px 37px;border-radius:24px;width:100%;max-width:1088px;background:#fff;z-index:10;@media screen and (max-width:960px){grid-template-columns:repeat(2,1fr);grid-template-rows:repeat(2,1fr);grid-gap:24px;padding:30px 21px;border:0.7px solid #e0d5ff;.value{font-size:24px;line-height:36px;}.label{font-size:12px;line-height:16px;}}"]);
export var StyledHomeDataPanel = styled.div.withConfig({
  displayName: "StyledHomeDataPanel",
  componentId: "sc-10fuyou-26"
})(["text-align:center;.value{font-size:37px;font-weight:800;line-height:56px;color:#000;margin-bottom:0;}.label{font-size:17px;line-height:23px;color:#7a7975;margin-bottom:0;}@media screen and (max-width:960px){padding-left:0;.value{font-size:24px;line-height:36px;}.label{font-size:12px;line-height:16px;}}"]);
export var StyledHomeHow = styled(StyledHomeSection).withConfig({
  displayName: "StyledHomeHow",
  componentId: "sc-10fuyou-27"
})(["background:#f6f8fb;padding:210px 0 60px 0;@media screen and (max-width:960px){padding-bottom:0;padding-top:400px;}"]);
export var StyledHomeHowContent = styled.div.withConfig({
  displayName: "StyledHomeHowContent",
  componentId: "sc-10fuyou-28"
})(["display:flex;flex-flow:row nowrap;justify-content:space-between;margin-top:40px;width:100%;@media screen and (max-width:960px){flex-flow:column nowrap;}"]);
export var StyledHomeHowItem = styled.div.withConfig({
  displayName: "StyledHomeHowItem",
  componentId: "sc-10fuyou-29"
})(["width:300px;display:flex;justify-content:center;align-items:flex-start;p{text-align:left;margin-left:10px;font-size:17px;line-height:27px;color:#4e5255;font-weight:300;margin-top:10px;&.item-title{font-size:21px;font-weight:normal;}}@media screen and (max-width:960px){flex-flow:column nowrap;padding-bottom:60px;width:100%;align-items:center;p{margin-left:0;text-align:center;padding:0 20px;&.item-title{margin-top:25px;}}}"]);
export var StyledDocsItem = styled.a.withConfig({
  displayName: "StyledDocsItem",
  componentId: "sc-10fuyou-30"
})(["flex:auto;display:flex;flex-direction:column;align-items:flex-start;padding:36px 26px;gap:20px;max-width:344px;background:#fcfaff;border:1px solid #f3efff;border-radius:25px;text-align:left;img{width:52px !important;height:52px;}.item-title{font-weight:600;font-size:20px;line-height:32px;color:#000000;margin-bottom:10px;}.item-desc{font-size:16px;line-height:26px;color:#777a8c;margin:0;}&:hover{border-color:#dfd5ff;}@media screen and (max-width:960px){max-width:342px;}"]);
export var StyledDocs = styled(StyledHomeSection).withConfig({
  displayName: "StyledDocs",
  componentId: "sc-10fuyou-31"
})(["padding:158px 0 6px;.carousel-root{width:100%;.carousel{margin:0 auto;.slide{display:flex;flex-direction:row;justify-content:center;align-items:center;background:transparent !important;", "{height:100%;}}.control-dots{display:flex;flex-flow:row nowrap;justify-content:space-between;position:static;text-align:center;margin:20.5px auto 0;padding:8px;width:212px;height:44px;background:#f7f7fa;border-radius:107.7px;}}}@media screen and (max-width:960px){padding:92px 0 104px;}", "{padding:0;}", "{padding:0 24px;}"], StyledDocsItem, StyledHomeContent, StyledHomeTitle);
export var StyledDocsContent = styled.div.withConfig({
  displayName: "StyledDocsContent",
  componentId: "sc-10fuyou-32"
})(["display:flex;justify-content:space-between;align-items:stretch;flex-wrap:nowrap;width:100%;"]);
export var StyledHomeNews = styled(StyledHomeSection).withConfig({
  displayName: "StyledHomeNews",
  componentId: "sc-10fuyou-33"
})(["padding:86px 0 87px;background-color:#f7f8ff;@media screen and (max-width:960px){padding:53px 0 54px;}"]);
export var StyledHomeNewsHeader = styled.div.withConfig({
  displayName: "StyledHomeNewsHeader",
  componentId: "sc-10fuyou-34"
})(["display:flex;justify-content:space-between;align-items:flex-end;width:100%;.help-center{font-size:19px;line-height:28px;color:#775df8;}@media screen and (max-width:960px){align-items:center;.help-center{font-size:15px;line-height:22px;}}"]);
export var StyledHomeNewsTitle = styled.h2.withConfig({
  displayName: "StyledHomeNewsTitle",
  componentId: "sc-10fuyou-35"
})(["font-weight:600;font-size:32px;line-height:48px;color:#212121;margin-bottom:0;@media screen and (max-width:960px){font-size:15px;line-height:22px;}"]);
export var StyledHomeNewsContent = styled.div.withConfig({
  displayName: "StyledHomeNewsContent",
  componentId: "sc-10fuyou-36"
})(["display:grid;grid-template-columns:710px 355px;grid-template-rows:repeat(2,232.54px);grid-gap:12px 24px;grid-template-areas:'first second' 'first  third';padding-top:26px;.first{grid-area:first;}@media screen and (max-width:992px){display:flex;justify-content:center;align-items:center;padding-top:31px;}"]);
export var StyledHomeNewsItem = styled.a.withConfig({
  displayName: "StyledHomeNewsItem",
  componentId: "sc-10fuyou-37"
})(["padding:24px;background:#ffffff;border:1px solid #f3efff;border-radius:24px;img{width:100%;height:118px;object-fit:cover;border-radius:12px;}.news-title{margin:12px 0 0;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;font-weight:600;font-size:20px;line-height:25px;color:#212121;}.news-date{margin:12px 0 0;font-size:14px;line-height:17px;color:#777a8c;}.news-digest{display:-webkit-box;margin:14px 0 0;text-overflow:ellipsis;overflow:hidden;-webkit-line-clamp:2;-webkit-box-orient:vertical;white-space:wrap;font-weight:400;font-size:16px;line-height:26px;color:#777a8c;em{span{word-break:break-all;}}}&:first-child{p{max-width:661px;}img{height:271px;}.news-title{margin:17.5px 0 0;font-size:32px;line-height:32px;color:#212121;}}&:hover{border-color:#dfd5ff;}@media screen and (max-width:992px){padding:21px;border:0.8px solid #dfd5ff;border-radius:21px;&:first-child{p{max-width:300px;}img{height:118px;}.news-title{margin:14px 0 0;font-size:15px;line-height:15px;}}}"]);