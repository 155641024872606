import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import React from "react";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { StyledFeatures, StyledHomeContent, StyledHomeTitle, StyledFeaturesContent, StyledFeaturesItem, StyledFeaturesItemButton } from "../styled";
import { useTranslation } from 'react-i18next';
import { useBreakpoint, useGA } from "../../../hooks";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import FeatureFigure1 from "../../../assets/images/feature-1.svg";
import FeatureFigure2 from "../../../assets/images/feature-2.svg";
import FeatureFigure3 from "../../../assets/images/feature-3.svg";
import EthereumLogo from "../../../assets/images/ethereum-logo.svg";
import ArbitrumLogo from "../../../assets/images/arbitrum-logo.svg";
var features = [{
  link: '/instant',
  figure: FeatureFigure1,
  itemBGColor: '#EAE8FF',
  borderColor: '#DFD5FF',
  buttonColor: '#5F4DBC',
  buttonHoverColor: '#775DF8',
  subtitleColor: '#6042F1',
  logos: [EthereumLogo]
}, {
  link: '/limit-order',
  figure: FeatureFigure2,
  itemBGColor: '#E7F7FF',
  borderColor: '#A7E3FF',
  buttonColor: '#457CFF',
  buttonHoverColor: '#6592FF',
  subtitleColor: '#457CFF',
  logos: [EthereumLogo, ArbitrumLogo]
}, {
  link: '/lon/staking',
  figure: FeatureFigure3,
  itemBGColor: '#E7F7FF',
  borderColor: '#A7E3FF',
  buttonColor: '#457CFF',
  buttonHoverColor: '#6592FF'
} // {
//   link: '/imbtc',
//   figure: FeatureFigure4,
//   itemBGColor: '#EAE8FF',
//   borderColor: '#DFD5FF',
//   buttonColor: '#5F4DBC',
//   buttonHoverColor: '#775DF8',
// },
];
export var Features = () => {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var _useBreakpoint = useBreakpoint(),
      isBreakPointLG = _useBreakpoint.isBreakPointLG;

  var _useGA = useGA(),
      trackElClick = _useGA.trackElClick;

  var indicatorStyles = {
    display: 'inline-block',
    width: 28,
    height: 28,
    borderRadius: '50%',
    fontSize: '16px',
    lineHeight: '28px',
    color: '#FFFFFF',
    background: '#D1D1E1'
  };
  return __jsx(StyledFeatures, null, __jsx(StyledHomeContent, null, __jsx(StyledHomeTitle, null, t('feature_title')), !isBreakPointLG ? __jsx(StyledFeaturesContent, null, features.map((feature, index) => {
    var idx = index + 1;
    var needShowLogo = idx === 1 || idx === 2;
    var link = feature.link,
        figure = feature.figure,
        itemBGColor = feature.itemBGColor,
        borderColor = feature.borderColor,
        buttonColor = feature.buttonColor,
        buttonHoverColor = feature.buttonHoverColor,
        subtitleColor = feature.subtitleColor,
        logos = feature.logos;
    var target = link.startsWith('/') ? '' : '_blank';
    return __jsx(StyledFeaturesItem, {
      key: idx,
      backgroundColor: itemBGColor,
      borderColor: borderColor,
      subtitleColor: subtitleColor
    }, __jsx("div", {
      className: "item-content"
    }, needShowLogo && __jsx("div", {
      className: "item-header"
    }, logos.map((logo, index) => __jsx("img", {
      key: index,
      src: logo,
      className: "item-logo",
      alt: "logo"
    }))), __jsx("h3", {
      className: "item-title"
    }, t("feat".concat(idx, "_title"))), __jsx("p", {
      className: "item-desc"
    }, t("feat".concat(idx, "_desc"))), __jsx(StyledFeaturesItemButton, {
      target: target,
      href: link,
      backgroundColor: buttonColor,
      hoverColor: buttonHoverColor
    }, __jsx("span", {
      "data-trackdata": JSON.stringify({
        title: t('feat${idx}_title'),
        url: link,
        index: idx
      }),
      "data-trackid": "webtl_homepage_explore",
      onClick: trackElClick
    }, t("feat".concat(idx, "_button"))))), __jsx("img", {
      src: figure,
      className: "figure",
      alt: "feature".concat(idx)
    }));
  })) : __jsx(Carousel, {
    showThumbs: false,
    axis: "horizontal",
    showIndicators: true,
    showStatus: false,
    showArrows: false,
    autoPlay: false,
    centerMode: true,
    emulateTouch: true,
    renderIndicator: (onClickHandler, isSelected, index, label) => {
      if (isSelected) {
        return __jsx("li", {
          style: _objectSpread(_objectSpread({}, indicatorStyles), {}, {
            background: '#1C1C1E'
          }),
          "aria-label": "Selected: ".concat(label, " ").concat(index + 1),
          title: "Selected: ".concat(label, " ").concat(index + 1)
        }, index + 1);
      }

      return __jsx("li", {
        style: indicatorStyles,
        onClick: onClickHandler,
        onKeyDown: onClickHandler,
        value: index,
        key: index,
        role: "button",
        tabIndex: 0,
        title: "".concat(label, " ").concat(index + 1),
        "aria-label": "".concat(label, " ").concat(index + 1)
      }, index + 1);
    }
  }, features.map((feature, index) => {
    var idx = index + 1;
    var needShowLogo = idx === 1 || idx === 2;
    var link = feature.link,
        figure = feature.figure,
        itemBGColor = feature.itemBGColor,
        borderColor = feature.borderColor,
        buttonColor = feature.buttonColor,
        buttonHoverColor = feature.buttonHoverColor,
        subtitleColor = feature.subtitleColor,
        logos = feature.logos;
    var target = link.startsWith('/') ? '' : '_blank';
    return __jsx(StyledFeaturesItem, {
      key: idx,
      backgroundColor: itemBGColor,
      borderColor: borderColor,
      subtitleColor: subtitleColor
    }, __jsx("div", {
      className: "item-content"
    }, needShowLogo && __jsx("div", {
      className: "item-header"
    }, logos.map((logo, index) => __jsx("img", {
      key: index,
      src: logo,
      className: "item-logo",
      alt: "logo"
    }))), __jsx("h3", {
      className: "item-title"
    }, t("feat".concat(idx, "_title"))), __jsx("p", {
      className: "item-desc"
    }, t("feat".concat(idx, "_desc"))), __jsx(StyledFeaturesItemButton, {
      target: target,
      href: link,
      backgroundColor: buttonColor,
      hoverColor: buttonHoverColor
    }, __jsx("span", null, t("feat".concat(idx, "_button"))))), __jsx("img", {
      src: figure,
      className: "figure",
      alt: "feature".concat(idx)
    }));
  }))));
};